<template>
  <div>
        <v-tabs>
          <v-tab to="/user/artworks/published" exact>Published</v-tab>
          <v-tab to="/user/artworks/custom" exact>Custom</v-tab>
        </v-tabs>
      <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  async created () {}
}
</script>
